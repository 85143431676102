@font-face {
  font-family: Gelion;
  src: url("../fonts/gellion/Gelion-Regular.otf") format("opentype"); }

* {
  box-sizing: border-box; }

.parent-size {
  width: 100%;
  height: 100%; }

body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  background-color: #f7ece0; }

h1 {
  font-size: 3rem;
  padding: 2% 0 0 0; }

h2,
h3,
h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bolder; }

h2 {
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-weight: 800;
  font-size: 36px;
  color: #1d8165; }

h3 {
  text-transform: uppercase;
  color: #1d8165;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-top: 0; }

h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600; }

h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px; }

ul {
  list-style: none;
  padding: 0; }

li {
  margin-bottom: 5px; }

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%; }

.header__container--base {
  position: fixed;
  z-index: 1;
  top: 0;
  height: 115px;
  padding-top: 40px;
  justify-content: space-between;
  background-color: #1d8165;
  box-shadow: 0px 5px 4px -3px rgba(0, 0, 0, 0.84); }
  .header__container--base > div > a {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    color: #f7ece0;
    font-size: medium;
    font-size: 1.5rem; }
    .header__container--base > div > a:hover {
      color: #d3c1ad; }
  .header__container--base > div > div > a {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    color: #f7ece0;
    font-size: medium;
    font-size: 1.5rem; }
    .header__container--base > div > div > a:hover {
      color: #d3c1ad; }

.header__logo--base {
  position: absolute;
  top: 5px;
  left: 55px;
  height: 100px; }

.header__home-container--base {
  display: flex;
  width: 40%;
  height: 100%;
  justify-content: center; }

.header__home--sm {
  box-sizing: border-box;
  padding: 5%;
  background-color: rgba(29, 129, 101, 0.644);
  border-bottom: 1px solid #d3c1adb2; }

.header__link-container--base {
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: space-evenly; }

.header__link-container--sm {
  position: absolute;
  top: 115px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: min-content;
  justify-content: space-evenly; }

.hamburguer-menu {
  font-size: 2rem;
  color: #fff;
  justify-self: right;
  position: absolute;
  height: 100%;
  align-self: center;
  top: 0;
  right: 25px; }

.landing__parallax-container--base {
  width: 100%;
  height: 95vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.landing__about--base {
  padding: 5% 5% 5%; }

.landing__about-title--base {
  padding-bottom: 5%; }

.landing__about-container--base {
  padding: 0 5%;
  width: 70%;
  font-size: 1.2rem; }
  .landing__about-container--base > div > h3 {
    margin-top: 15px; }

.landing__about-image--base {
  padding: 20px;
  height: 300px;
  margin-bottom: 30px; }
  .landing__about-image--base > img {
    height: 300px;
    object-fit: cover; }

.landing__products--base {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2% 5% 0 5%;
  justify-content: space-evenly;
  align-items: flex-start; }

.landing__product {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 425px;
  justify-content: center;
  align-items: flex-start; }
  .landing__product-image {
    width: 350px;
    height: 350px;
    object-fit: cover;
    justify-self: flex-start;
    align-self: center; }
  .landing__product-info--base {
    display: flex;
    flex-direction: column;
    padding: 5% 15% 15%;
    font-size: 1.2rem;
    width: 100%; }

.landing__claims--base {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2% 5% 2% 5%;
  justify-content: space-evenly;
  align-items: flex-start; }

.landing__claim {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 425px;
  justify-content: center;
  align-items: flex-start; }
  .landing__claim-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
    justify-self: flex-start;
    align-self: center; }
    .landing__claim-image.longer {
      width: 200px; }

#claims {
  padding-top: 145px; }

#frozen_products {
  background-color: #1d8165;
  color: #f7ece0;
  padding-top: 5%; }
  #frozen_products h2 {
    color: #f7ece0; }
  #frozen_products h3 {
    color: white; }

#contact {
  padding: 2% 2% 15px;
  background-color: #f7ece0;
  color: #1d8165; }

#contact h2 {
  color: #1d8165;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px; }

#contact h3 {
  color: #1d8165;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400; }

#contact a {
  text-decoration: none;
  color: #1d8165; }
  #contact a:hover {
    color: #1d8165; }

#contact form {
  padding-top: 20px; }

#contact .text-danger {
  color: #cc0033;
  text-align: left; }

#contact .btn-custom {
  margin: 20px 0;
  background: transparent;
  color: #1d8165;
  border: 2px solid #1d8165; }

#contact .btn-custom:hover {
  color: #f7ece0;
  background: #1d8165; }

label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left; }

textarea {
  font-family: inherit;
  font-size: inherit;
  font-family: "Open Sans", sans-serif; }

#contact .form-control {
  font-family: "Open-Sans", sans-serif;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none; }

#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent; }

.form-control::-webkit-input-placeholder {
  font-family: "Open-Sans", sans-serif;
  color: #777; }

.form-control:-moz-placeholder {
  font-family: "Open-Sans", sans-serif;
  color: #777; }

.form-control::-moz-placeholder {
  font-family: "Open-Sans", sans-serif;
  color: #777; }

.form-control:-ms-input-placeholder {
  font-family: "Open-Sans", sans-serif;
  color: #777; }

#contact .contact-item {
  margin: 20px 0; }

#contact .contact-item span {
  color: #1d8165;
  margin-bottom: 10px;
  display: block; }

#contact .contact-item .fa {
  margin-right: 10px; }

#contact .social {
  padding-top: 10px;
  margin-top: 10px;
  text-align: center; }
  #contact .social > ul {
    padding: 0; }

#contact .social ul li {
  display: inline-block;
  margin: 0 20px; }

#contact .social .fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #1d8165;
  color: #1d8165;
  border-radius: 50%;
  transition: all 0.3s; }
  #contact .social .fa:hover {
    color: #f7ece0;
    background: #1d8165; }

.contact__main--base {
  display: flex;
  flex-direction: column;
  width: 75%; }

.contact__title--base {
  display: flex;
  flex-direction: column; }

.contact__input-row--base {
  justify-content: space-between; }

.contact__input--base {
  width: 49%; }

.contact-info {
  padding: 0 2%;
  align-self: flex-start; }

.contact__button--base {
  font-family: "Open-Sans", sans-serif;
  color: #1d8165;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: lighter;
  padding: 2% 5%;
  margin-top: 3%;
  border-radius: 25px;
  background-color: rgba(43, 43, 43, 0.911); }
  .contact__button--base:hover {
    background-color: rgba(59, 59, 59, 0.849); }

@media (max-width: 1000px) {
  .header__logo--base {
    position: absolute;
    top: 25px;
    left: 15px;
    height: 60px; }
  .header__home-container--base {
    width: 25%; }
  .header__link-container--base {
    width: 75%; }
    .header__link-container--base > a {
      margin-top: 5px;
      font-size: 1.2rem !important; }
  .contact__main--base {
    width: 90%; }
  .landing__parallax-container--base {
    background-position: center;
    background-size: cover;
    background-attachment: scroll; }
  .landing__about-container--base {
    padding: 0; }
    .landing__about-container--base > div > h3 {
      padding-top: 30px; } }
